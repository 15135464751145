<template>
  <div class="" style="margin: 0 auto; text-align: center">

    <div class="hiddendiv"></div>

    <p class="forgot-text" style="">Hesabınla bağlantılı e-posta adresini girdiğinde, bu e-posta adresine parolanı sıfırlaman için bir bağlantı göndereceğiz.</p>

    <div class="hiddendiv20"></div>

    <div style="margin:0 auto; text-align: center">
      <form @submit.prevent="forgotPassword(email)">
        <input type="text" :laceholder="E-Posta" v-model="email" style=" height: 24px; padding-top: 10px; font-size: 16px;">
        <div class="hiddendiv20"></div>
        <button :disabled="disableResetEmail" type="submit" class="super-button-green">Şifremi Sıfırla</button>

      </form>
    </div>

    <div class="hiddendiv"></div>

    <p class="subheading2-text">
      <router-link :to="'/login'" style="font-weight: 500; color: #8b95a1">Geri dön</router-link>
    </p>

  </div>

</template>

<script>

  import mixin from '../mixin';

  export default {
    name: "src-pages-body-auth-forgotpasswordpage-mobile-v1_mobile",
  	mixins: [mixin],

    data() {
      return { }
    },

    methods: { }
  }

</script>

<style scoped>
  .forgot-text{
    width:400px; margin:0 auto;
  }

  @media only screen and (max-width: 768px) {
    .forgot-text{
      width:80%;

    }
    input{ width: 240px!important;}

    .sifresifirlaheader{
      display: block!important;
      margin-top: 30px;
    }
  }
</style>

