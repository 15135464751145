/**
 * Created by haluk on 7/31/17.
 */


export default {
  data() {
    return {
      email: "",
      disableResetEmail: false
    }
  },

  methods: {
    forgotPassword(email) {
      this.disableResetEmail = true;
      this.api.user.forgotPassword(email, this.$Progress).then(({data}) => {
        let result = data;
        this.disableResetEmail = false;
        if (result.success) {
          this.$toasted.global.infoToast({description: result.message});
        } else {
          this.$toasted.global.errorToast({description: result.message});
        }
      }).catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      });
    }
  }
}
